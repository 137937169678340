import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useClubsList() {
  const toast = useToast();
  const records = ref(null);

  // Table Handler
  const columns = [{ key: "name", label: "Club Name", sortable: true }, { key: "growers" }, { key: "address", label: "Location" }, { key: "sector" }, "actions"];

  const from = ref(0);
  const to = ref(0);
  const meta = ref({ from: 0, to: 0, total: 0 });
  const perPage = ref(10);
  const totalRecords = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [5, 10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("name");
  const isSortDirDesc = ref(true);
  const locationFilter = ref(null);
  const sectorFilter = ref(null);

  const refetch = () => {
    records.value.refresh();
  };

  watch([locationFilter, sectorFilter, currentPage, perPage, searchQuery, sortBy, isSortDirDesc], () => {
    refetch();
  });

  const fetch = (ctx, callback) => {
    store
      .dispatch("cromis-club/list", {
        location_id: locationFilter.value,
        sector_id: sectorFilter.value,
        query: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        const { clubs, meta } = response.data;

        meta.value = meta;

        callback(clubs);
        totalRecords.value = meta.value.total;
        from.value = meta.value.from;
        to.value = meta.value.to;
      })
      .catch((error) => {
        console.log(error);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching clubs list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveGrowerTypeVariant = (society) => {
    if (society !== null) return "success";
    return "primary";
  };

  const resolveStatusVariant = (status) => {
    if (status === 1) return "success";
    if (status === 0) return "secondary";
    return "warning";
  };

  return {
    fetch,
    refetch,
    columns,
    perPage,
    currentPage,
    totalRecords,
    from,
    to,
    meta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    records,

    // resolveUserRoleVariant,
    // resolveUserRoleIcon,
    resolveGrowerTypeVariant,
    resolveStatusVariant,

    // Extra Filters
    // roleFilter,
    // planFilter,
    // statusFilter,
    locationFilter,
    sectorFilter,
  };
}
