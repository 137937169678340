<template>
  <div>
    <!-- Form Modal -->
    <b-modal id="modal-season-form" ref="myModal" centered no-close-on-backdrop no-close-on-esc size="md" @ok="submit">
      <!-- Custom Modal Header -->
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 v-if="club.id === null">Add Club</h5>
        <h5 v-if="club.id !== null">Edit Club</h5>

        <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
      </template>

      <!-- Custom Modal Footer -->
      <template #modal-footer="{ ok, cancel }">
        <b-button size="md" variant="primary" @click="ok()" :disabled="saving">
          <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
          <span v-if="!saving">Save</span>
          <span v-if="saving">Saving...</span>
        </b-button>
        <b-button size="md" @click="cancel()" variant="outline-secondary"> Cancel </b-button>
      </template>

      <!-- Data Form in Modal Body -->
      <template #default="{}">
        <validation-observer ref="dataForm" #default="{ invalid }">
          <b-form ref="form" @submit.stop.prevent="handleCreate">
            <b-row>
              <b-col cols="6" v-for="(selected, index) in formSelections" :key="selected.level">
                <b-form-group :label-for="selected.level" :label="selected.label">
                  <validation-provider #default="{ errors }" :name="selected.label" rules="required">
                    <b-form-select :id="selected.level" :name="selected.level" size="md" :state="errors.length > 0 ? false : null" v-model="selected.model" @change="changeFormSelection(selected.model, index)">
                      <b-form-select-option :value="null">Select...</b-form-select-option>
                      <b-form-select-option v-for="location in selected.locations" :key="location.id" :value="location">
                        {{ location.name.toUpperCase() }}
                      </b-form-select-option>
                    </b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{ serverErrors.location_id[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label-for="sector_id" label="Sector">
                  <validation-provider #default="{ errors }" name="Sector" rules="required">
                    <b-form-select id="sector_id" name="sector_id" size="md" :state="errors.length > 0 ? false : null" v-model="club.sector_id">
                      <b-form-select-option :value="null">Select...</b-form-select-option>
                      <b-form-select-option v-for="sector in formSectors" :key="sector.id" :value="sector.id">
                        {{ sector.code.toUpperCase() }}
                      </b-form-select-option>
                    </b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small class="text-danger" v-if="serverErrors && serverErrors.sector_id">{{ serverErrors.sector_id[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group labe-for="name" label="Club">
                  <validation-provider #default="{ errors }" name="Club Name" rules="required">
                    <b-form-input id="name" name="name" v-model="club.name" :state="errors.length > 0 ? false : null" size="md" value="" />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small class="text-danger" v-if="serverErrors && serverErrors.name">{{ serverErrors.name[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- <b-card>
                            {{ club }}
                        </b-card> -->
          </b-form>
        </validation-observer>
      </template>
    </b-modal>
    <!-- ./Form Modal -->

    <!-- Filters -->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>Filter Location</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="3" class="mb-md-0 mb-2" v-for="(toSelect, index) in selections" :key="toSelect.level">
            <label>{{ toSelect.label }}</label>
            <b-form-select size="md" v-model="toSelect.model" @change="changeFilters(toSelect.model, index)">
              <b-form-select-option :value="null">Select...</b-form-select-option>
              <b-form-select-option v-for="location in toSelect.locations" :key="location.id" :value="location">
                {{ location.name.toUpperCase() }}
              </b-form-select-option>
            </b-form-select>
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label for="sector_filter">Sector</label>
            <b-form-select id="sector_filter" name="sector_filter" size="sm" v-model="sectorFilter">
              <b-form-select-option :value="null">Select...</b-form-select-option>
              <b-form-select-option v-for="sector in sectors" :key="sector.id" :value="sector.id">
                {{ sector.code.toUpperCase() }}
              </b-form-select-option>
            </b-form-select>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- ./Filters -->

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50 select-size-sm" />
            <label>entries</label>
          </b-col>
          <!-- ./Per Page -->

          <!-- Search & Button -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." size="sm" />
              <b-button variant="primary" size="sm" @click="invokeCreateForm()">
                <span class="text-nowrap">Add Club</span>
              </b-button>
            </div>
          </b-col>
          <!-- ./Search & Button-->
        </b-row>
        <!-- ./Table Top -->
      </div>

      <!-- Table -->
      <b-table striped hover small ref="records" class="position-relative" :items="fetch" responsive :fields="columns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No records found" :sort-desc.sync="isSortDirDesc">
        <template #cell(name)="data">
          {{ data.item.name.toUpperCase() }}
        </template>

        <template #cell(growers)="data">
          {{ data.item.growers }}
        </template>

        <template #cell(address)="data">
          <span v-if="data.item.address"> {{ data.item.address.location.name }}</span>
        </template>

        <template #cell(sector)="data">
          {{ data.item.sector.code.toUpperCase() }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>

            <b-dropdown-item @click="invokeUpdateForm(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="remove(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <!-- Table Footer -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination, BForm, BFormGroup, BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem, BSpinner } from "bootstrap-vue";
import vSelect from "vue-select";
import { required } from "@validations";
import store from "@/store";
import { ref, onMounted, onUnmounted } from "@vue/composition-api";
import clubsStoreModule from "@/views/cromis/clubs/clubsStoreModule";
import useClubsList from "@/views/cromis/clubs/useClubsList";
export default {
  props: {},
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BTable,
    BPagination,
    BForm,
    BFormGroup,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormInput,
    vSelect,
    BDropdown,
    BDropdownItem,
    BSpinner,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {},

  setup(props, context) {
    const dataForm = ref(null);
    const myModal = ref(null);
    const saving = ref(false);
    const serverErrors = ref(null);
    const sectors = ref([]);
    const formSectors = ref([]);
    const selections = ref([]);
    const formSelections = ref([]);
    const levels = ref(null);
    const children = ref([]);

    const club = ref({
      id: null,
      name: null,
      location_id: null,
      sector_id: null,
    });

    const CROMIS_STORE_MODULE_NAME = "cromis-club";

    // Register module
    if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, clubsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME);
    });

    onMounted(async () => {
      await store
        .dispatch("cromis-club/levels")
        .then((response) => {
          levels.value = response.data.levels[0];
          selections.value.push({ level: levels.value.name.toLowerCase(), model: null, label: levels.value.name, child: levels.value.children[0].name.toLowerCase(), locations: [] });
          formSelections.value.push({ level: levels.value.name.toLowerCase(), model: null, label: levels.value.name, child: levels.value.children[0].name.toLowerCase(), locations: [] });
          let list = levels.value.children;

          while (list.length > 0) {
            children.value.push(list[0]);
            if (list[0].children > 0) {
              selections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: list[0].children[0].name.toLowerCase(), locations: [] });
              formSelections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: list[0].children[0].name.toLowerCase(), locations: [] });
            } else {
              selections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: null, locations: [] });
              formSelections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: null, locations: [] });
            }

            list = list[0].children;
          }
        })
        .catch((error) => {
          console.log("got error", error);
        });

      await store
        .dispatch("cromis-club/locations")
        .then((response) => {
          populateSelections(response.data.locations);
        })
        .catch((error) => {
          console.log("got error", error);
        });
    });

    const populateSelections = (locations) => {
      for (let i = 0; i < locations.length; i++) {
        for (let x = 0; x < selections.value.length; x++) {
          if (locations[i].level.name.toLowerCase() === selections.value[x].level) {
            selections.value[x].locations.push(locations[i]);

            if (x === 0) {
              formSelections.value[x].locations.push(locations[i]);
            }
          }
        }
      }
    };

    const changeFilters = async (location, index) => {
      if (!location) {
        for (let i = index + 1; i < selections.value.length; i++) {
          selections.value[i].locations.splice(0);
          selections.value[i].model = null;
        }
        sectors.value.splice(0);
        sectorFilter.value = null;

        if (index > 0) {
          locationFilter.value = selections.value[index - 1].model.id;
        } else {
          locationFilter.value = null;
        }
      } else {
        sectors.value.splice(0);
        if (selections.value[index + 1]) {
          selections.value[index + 1].locations.splice(0);
        }

        location.children.map((child) => {
          selections.value[index + 1].locations.push(child);
        });

        if (location.children.length > 0) {
          selections.value[index + 1].model = null;
        }

        locationFilter.value = selections.value[index].model.id;

        if (index === selections.value.length - 1) {
          await store
            .dispatch("cromis-club/sectors", {
              location_id: selections.value[index].model.id,
              sortBy: "code",
              sortDesc: false,
            })
            .then((response) => {
              sectors.value = response.data.sectors;
            })
            .catch((error) => {
              console.log("got error", error);
            });

          sectorFilter.value = null;
        }
      }
    };

    const changeFormSelection = async (location, index) => {
      if (!location) {
        for (let i = index + 1; i < formSelections.value.length; i++) {
          formSelections.value[i].locations.splice(0);
          formSelections.value[i].model = null;
        }
        formSectors.value.splice(0);
        club.value.sector_id = null;
      } else {
        formSectors.value.splice(0);
        club.value.sector_id = null;

        if (formSelections.value[index + 1]) {
          formSelections.value[index + 1].locations.splice(0);
        }

        location.children.map((child) => {
          formSelections.value[index + 1].locations.push(child);
        });

        if (location.children.length > 0) {
          formSelections.value[index + 1].model = null;
        }

        if (index === formSelections.value.length - 1) {
          club.value.location_id = location.id;
          await store
            .dispatch("cromis-club/sectors", {
              location_id: formSelections.value[index].model.id,
              sortBy: "code",
              sortDesc: false,
            })
            .then((response) => {
              formSectors.value = response.data.sectors;
            })
            .catch((error) => {
              console.log("got error", error);
            });
        }
      }
    };

    const {
      fetch,
      columns,
      perPage,
      currentPage,
      totalRecords,
      from,
      to,
      meta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      records,
      refetch,

      // Filers
      locationFilter,
      sectorFilter,
    } = useClubsList();

    // Form Methods
    const invokeUpdateForm = (item) => {
      serverErrors.value = null;

      let location = item.address.location;
      let selectedLocations = [];
      while (location) {
        selectedLocations.push({ level: location.level.name.toLowerCase(), id: location.id });
        location = location.parent;
      }

      for (let i = 0; i < formSelections.value.length; i++) {
        for (let n = 0; n < selectedLocations.length; n++) {
          if (formSelections.value[i].level === selectedLocations[n].level) {
            for (let x = 0; x < formSelections.value[i].locations.length; x++) {
              if (formSelections.value[i].locations[x].id === selectedLocations[n].id) {
                formSelections.value[i].model = formSelections.value[i].locations[x];

                if (i + 1 < formSelections.value.length) {
                  formSelections.value[i + 1].locations = formSelections.value[i].locations[x].children;
                } else {
                  store
                    .dispatch("cromis-club/sectors", {
                      location_id: formSelections.value[i].model.id,
                      sortBy: "code",
                      sortDesc: false,
                    })
                    .then((response) => {
                      formSectors.value = response.data.sectors;
                    })
                    .catch((error) => {
                      console.log("got error", error);
                    });
                }
              }
            }
          }
        }
      }

      club.value = {
        id: item.id,
        location_id: item.address.location.id,
        sector_id: item.sector.id,
        name: item.name,
      };

      myModal.value.show();
    };

    const invokeCreateForm = () => {
      formSelections.value.map((form) => {
        form.model = null;
      });

      club.value = {
        id: null,
        location_id: null,
        sector_id: null,
        name: null,
      };

      myModal.value.show();
    };

    const isFormValid = async () => {
      let isValid = false;
      await dataForm.value.validate().then((success) => {
        isValid = success;
      });

      return isValid;
    };

    const submit = (bvModalEvt) => {
      bvModalEvt.preventDefault();

      serverErrors.value = null;
      // Handle form submit
      if (club.value.id === null || club.value.id === 0) handleCreate();
      else handleUpdate(club.value);
    };

    const handleCreate = async () => {
      saving.value = true;
      const valid = await isFormValid();

      if (!valid) {
        saving.value = false;
        return;
      }

      await store
        .dispatch("cromis-club/create", club.value)
        .then((response) => {
          refetch();
          saving.value = false;

          myModal.value.hide();

          context.root.$swal({
            icon: "success",
            text: `The club has been added successfully!`,
            showConfirmButton: true,
            timer: 2000,
            customClass: {
              confirmButton: "btn btn-outline-success",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          saving.value = false;
          if (error.response.status === 422) {
            serverErrors.value = error.response.data.errors;
          } else {
            context.root.$swal({
              icon: "error",
              title: "Server Error",
              text: "Something went wrong. See tech support",
              showConfirmButton: true,
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
          }
        });
    };

    const handleUpdate = async (item) => {
      saving.value = true;
      const valid = await isFormValid();

      if (!valid) {
        saving.value = false;
        return;
      }

      await store
        .dispatch("cromis-club/update", { id: item.id, data: item })
        .then((response) => {
          refetch();
          saving.value = false;

          myModal.value.hide();

          context.root.$swal({
            icon: "success",
            text: `Changes to sector ${response.data.code} has been saved successfully!`,
            showConfirmButton: true,
            timer: 5000,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          saving.value = false;
          if (error.response.status === 422) {
            serverErrors.value = error.response.data.errors;
          } else {
            context.root.$swal({
              icon: "error",
              title: "Server Error",
              text: "Something went wrong. See tech support",
              showConfirmButton: true,
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
          }
        });
    };

    const remove = async (id) => {
      await store
        .dispatch("cromis-club/remove", id)
        .then((response) => {
          refetch();
        })
        .catch((error) => {
          context.root.$swal({
            icon: "error",
            title: "Server Error",
            text: "Something went wrong. See tech support",
            showConfirmButton: false,
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
        });
    };

    return {
      fetch,
      columns,
      perPage,
      currentPage,
      totalRecords,
      from,
      to,
      meta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      records,
      refetch,

      required,
      dataForm,
      myModal,
      saving,
      serverErrors,
      sectors,
      formSectors,
      selections,
      formSelections,
      levels,
      children,

      // Form
      club,
      invokeCreateForm,
      invokeUpdateForm,
      submit,
      isFormValid,
      handleCreate,
      handleUpdate,
      remove,

      // Filers
      changeFilters,
      changeFormSelection,
      locationFilter,
      sectorFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
</style>
